<template>
  <b-card title="Webinar Participant List">
    <b-col cols="12 row text-left" class="p-0 mt-1">
      <b-col cols="col-2 md-2 sm-6 ml-1 mb-2">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal
        >
          Export Webinar Participants
        </b-button>
      </b-col>
    </b-col>

    <b-row class="justify-content-start">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Start Date:</label>
          <flat-pickr
            id="start-date"
            v-model="filter.start_date"
            class="form-control"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">End Date:</label>
          <flat-pickr
            id="end-date"
            v-model="filter.end_date"
            class="form-control"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :participants="participants"
      :is-loading="isLoading"
      :get-data="getData"
      :current-page="currentPage"
    />

    <Modal
      :get-history="getHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams ="filter"
    />
  </b-card>
</template>

<script>
import Table from "@/components/webinar/TableParticipant.vue";
import Modal from "@/components/webinar/ModalParticipant.vue";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    flatPickr,
    Modal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      participants: [],
      link: "",
      isLoadingExport: false,
      formPayload: {
        name: "",
      },
      filter: {},
      modalData: {},
      histories: [],
      columns: [],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;

      this.$http
        .get(
          `/api/v1/admin/communication-activities/webinar/${this.$route.params.uuid}/participants`,
          {
            params: this.filter,
          }
        )
        .then((response) => {
          this.result = response.data.data.meta;
          this.participants = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHistory() {
      this.$http
        .get(
          `/api/v1/admin/export/history/webinar-participants?filter_key=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=webinar-participants")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
